.Userstable {
    width: 100%;
    max-height: 70vh;
    display: flex;
    margin-top: 10px;
  }
.Users{
    flex: 1;
    width: 100%;
    margin-left: -10px;
    float: left;
    display: inline-flex;
    padding: 3rem;
    margin-top: 0rem;
}
.formDetails {
	right: 0;
	padding-top: 30px;
	padding-left: 4.8px;
	flex: 1;
	width: 50%;
}
.containerResults {
	display:inline-flex;
	margin: 1rem;
	padding: 2rem 2rem ;
	width: 50%;
	margin-top: 2.6rem;
}
/* .border{
   border: 5px solid black;
  } */
.fields{
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  width: 40vw;
  height: 20vh;
}
.notAssigned{
  display: block;
  color:rgb(74, 74, 119) ;
  /* color: rgb(95, 98, 255); */
  text-align: center ;
  margin-left: 10vw;
  margin-bottom: 5vh; 
  width: 15vw;
  height: 45px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px
}

.css-12q85cb-MuiTableCell-root {
  color: white !important;
}