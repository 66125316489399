.tabheader {
    display: inline-flex;
    padding: 0.1rem;
    margin-top:0.1rem;
    width: 100%;
}

h1 {
  margin: 
  100px 
  70px 
  80px 
  20px;
  }
  .splitpdftab{
      margin-top: 00.1rem;
  }
  .test {
    width:200px;
    display:flex;
    align-items: right;
    overflow: auto;
    white-space: nowrap;
    margin:0px auto;
  }
.header-btn{
height: 35px;
margin-left: 60%;
margin-top: 13px;
margin-bottom: -25px;
display: flex;
position: relative;
flex-direction: row;
justify-content: end;
}
.upload-btn{
  margin-right: 5px !important;
}
.local-btn{
  margin-right: 5px !important;
}
.email-btn{
  margin-right: 5px;
}