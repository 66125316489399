.download-table-xls-button {
    margin-top: 10px;
    margin-left: 50%;
    margin-bottom: 10px;
    background-color: blue;
    color: azure;
}

#test-table-xls-button {
    padding: '3px 1px';
    border-radius: 3PX;
    border: 2px solid black;
    width: 140px;
    margin: 10px 10px;
    transform: uppercase;
    font-size: 15px;
}

#dropdown-basic-button{
    width: 100px;
    margin-left: 50vw;
    height: 40px;
}