#rootTable{
    width: 100% !important;
    height: 250px !important;
}

#containerTable{
    height: 250px !important;
   max-height: 250px !important;
}

.newCol {
    margin: 10px
}

#columnheader {
    justify-content: space-between;
    display: flex;
    margin: auto;
}
.headcolumns{
    font-size: 25px;
}

.hide {
    display: none;
}

.lineitems-field-span{
    max-width: 25%;
} 

.main-input{
    display: flex;
    flex-direction: column
}

.lineitems-input{
    max-width:100%
}