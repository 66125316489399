.containerimage {
	display: inline-flex;
	padding: 1rem;
	width: 100%;
	margin-top: 4.6rem;
}

.imagedisplay{
	position: relative;
	margin-left: 10px;
}
.img1container {
	position: relative;
	float: left;
	width: auto;
	height: 200px;
	margin-left: 10px;
}
.checkbox1 {
	position: absolute;
	right: 20px;
	top: 20px;
	bottom: 40px;
}