#tableContainerExtra {
    width: 100%;
    max-height: 300px !important;
}

#tableContainerExtra {
    margin-top: 10px;
    margin-bottom: 30px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 12px;
    } 


#rawTablesContainer {
    overflow: auto;
    width: 100%;
    height: 350px;
}
