@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

#mainImg {
    max-height: 25rem;
    min-height: 25rem;
    flex: 1;
}

.table{
    height:  calc(100vh-4rem) !important;
}
#btnUploadDoc {
    padding-left: 50%;
}  
.headertab{
    display: inline-flex;
    padding: 2rem;
    margin-top:3rem;
    width: 100%;
    
}

#btn {
    margin: auto;
    flex: 1;
}

#fileGirl {
    display: block;
    margin-left: 30%;
    height: 250px;
    width: 380px;
    margin-bottom: 20px;
}