.download-table-xls-button {
    margin-top: 10px;
    margin-left: 50%;
    margin-bottom: 10px;
}

#test-table-xls-button {
    padding: '3px 1px';
    border-radius: 3PX;
    border: 2px solid black;
    width: 140px;
    margin: 10px 10px;
    transform: uppercase;
    font-size: 15px;
}

#dropdown{
   width: 120px;
   margin-right: 30px;
}
.loader{
    color: rgba(0,0,0,0.6);
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2),
    0px -5px 35px rgba(255,255,255,0.3);
    font-size: 25px;
}