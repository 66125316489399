.ellipse{
    height: 30px;
    width: 60px;
    margin-left: 10px;
    color:white;
    border: 1px solid #8DABC4;
    padding:2px 8px;
    background-color: #2196f3;
    border-radius: 16px;
}
.set-2{
    display: flex;
    float: right;
    flex-direction: row;
    margin-left: auto;
}
/* .refresh-btn{
    color: black;
    border: 1px solid black;
}
.refreshApproved-btn{
    border: 1px solid black;
    margin-left: 10px;
} */