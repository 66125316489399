
#empty {
  display: none;
}

#mainCont {
  min-height: 100vh;
}
body::-webkit-scrollbar {
  width: 12px;         /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #e28000;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: white;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #e28000  /* creates padding around scroll thumb */
}