.containerSplit {
	display: inline-flex;
	padding: 1rem;
	width: 100%;
	margin-top: 4.6rem;
}

.react-pdf__Document {
	display: flex;
	flex-wrap: wrap;
}

.mainimg {
	flex: 1;
	height: 500px;
	width: 100%;
	margin-right: 25px;
}
.img {
	height: 500px;
}

.imgcontainer {
	position: relative;
	width: auto;
	height: 200px;
	float: right;
	margin-left: 10px;
}
.checkbox {
	position: absolute;
	right: 20px;
	top: 20px;
	bottom: 40px;
}
.checkbox1 {
    height: 25px;
    width: 25px;
}


#itemTable {
	height: 430px;
	width: 100%;
	margin-top: -30px;
	flex: 1;
}

.react-pdf__Page__canvas {
	flex: 1 !important;
	width: 150px !important;
	height: 185px !important;
	padding: 0em !important;
	margin: 1em !important;
	border: 1px solid black !important;
}
.dialog {
	box-shadow: 0 8px 6px -6px black;
	position: static;
	left: 20%;
	top: 10%;
}

.image {
	width: 300px !important;
}


.btn{
	margin-top: 0%;
	display: flex;
	float: right;
	flex-direction: column;
}

.processBtn{
	background-color: blue !important;
	margin: 10px
}

.gobackBtn{
	background-color: blue !important;
	margin-top: 7rem !important;
}

.single-file-btn{
	background-color: blue !important;
	margin-top: 7rem !important;
}

.react-pdf__Page__textContent{
	display:none
}

.react-pdf__Page__textContent{
	display:none
}
.close-tab-Btn{
	background-color: blue !important;
	margin-bottom: 5rem !important;
    margin-top: 2rem !important;
}
/* .css-rqglhn-MuiTable-root{
	width: 53%;
} */
/* @media (max-width: 350px) {
.btn{
	margin-right: -107px;
}
}

@media (max-width: 250px) {
	.btn{
		margin-right: -182px;
	}
}

@media (max-width: 1478px) and (min-width: 1100px) {
.btn{
	margin-right: 91px;
}
} */
