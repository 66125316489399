#loadingImg {
    margin-top: 50%;
    margin-top: 50%;
    margin-bottom: auto;
    width: 450px;
    height: 170px;
}
.MuiFilledInput-input {
    padding: 10px 10px 10px;
}
.MuiGrid-grid-xs-12 {
    flex-grow: 0;
    max-width: 70% !important;

}

#label{
    color: rgb(40, 106, 203);
    font: 25px monospace ;
    /* margin-left: 10px !important; */
    padding-top: 10px;
}

.MuiExpansionPanelSummary-content {
    margin: 0 !important;

}

.MuiInputBase-fullWidth {
    min-height: 60px !important;
   
}