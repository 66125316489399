/* #imgFileDisplay {
    flex: 1;
    width: 50%;
    height: 100%;
    margin-right: 25px;
    margin-bottom: 0;
} */


#iFrameFileDisplay {
    flex: 1;
    height: 120vh;
    width: 100vh;
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px;
    border: 1px solid rgb(73, 72, 72);


}

.main {
    flex: 1;
    height: 130vh;
    width: 100%;
    margin-left: -10px;
    float: left;
}

@media screen and (min-width: 1240px) {
    .main{
        margin-right: -14px;
        margin-left: -20px;
    }
}

@media screen and (min-width: 1280px) {
    .main{
        margin-right: -10px;
        margin-left: -7px;
    }
}

@media screen and (min-width: 1366px) {
    .main{
        margin-right: 5px;
        margin-left: -6px;
    }
}  

@media screen and (max-width: 1024px) {
    .main{
        margin-right: 5px;
        margin-left: -6px;
    }
}

.Pre {
    margin: 5px;
}

.nxt {
    margin: 5px;
}


.react-pdf__Page__canvas {
    flex: 1 !important;
    width: 400px !important;
    height: 500px !important;
    padding: 0em !important;
    margin: 1em !important;
    border: 1px solid black !important;
}

#imgConatiner {
    flex: 1 !important;
    width: 600px !important;
    height: auto !important;
    min-height: 500px !important;
    margin: 0 auto !important;
}

.pagec {
    display: flex;
    align-items: left;

}

.zoom {
    position: relative;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom : -30px;
    z-index: 1;
    background-color: white;
}

.zoomin {
    margin-right: 5px;
    margin-left: 30px;
    padding: 0px;
}

.zoomout {
    margin-right: 5px;
    padding: 0px;
}

.cancel {
    margin-right: 45px;
    padding: 0px;
}

.imageContainer {
    cursor: crosshair;
    margin-top: 40px;
}

.rotate {
    margin-left: -40px;
}

.download-btn{
    margin-left: 5px;
}

.zoomBtn {
    margin-left: 17px;
}

.magnifyeBtn {
    margin-left: 5px;
}

.magnify{
    margin-top: 40px;
}