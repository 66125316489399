#warningMsg {
    color : rgb(190, 86, 0);
    text-align: justify;
    font-weight: 500;
    font-size: larger;
    margin-left: 50px;
}

#btnUploadDocError {
    margin-left: 50px;
    margin-top: 45px;
}