@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.navContainer {
	width: 100%;
	height: fit-content;
	background-color: #ffffff;
	display: inline-flex;
	padding: 0.5rem;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	position: fixed;
	z-index: 999;
}

#refreshBtn {
	height: 35px;
	margin-top: 13px;
	margin-left: 5px;
	margin-right: 20px;
}

.hide{
	display: none;
}

#imglogo {
	height: 3rem;
	margin-bottom: 0.25rem;
	margin-left: 1rem;
}

img:hover {
	cursor: pointer;
}

h3 {
	font-family: 'Roboto', sans-serif;
	color: #e28000;
	margin-top: auto;
	margin-bottom: auto;
	margin-left: 0.1rem;
}

h3:hover {
	cursor: pointer;
}

#uploadHeaderBtn1 {
	height: 35px;
	margin-left: 60%;
	margin-right: 5px;
	margin-top: 13px;
}


#homeBtn {
	height: 35px;
	margin-left: 0.1%;
	margin-top: 13px;
	margin-right: 00.5%;
}


#logoutBtn1 {
	height: 35px;
	margin-top: 13px;
}

#logout {
	height: 35px;
	margin-left: 75%;
	margin-top: 13px;
}

#uploadHeaderBtn {
	height: 35px;
	margin-right: 5px;
	margin-top: 13px;
}

#logoutBtn {
	height: 35px;
	margin-top: 13px;
}

#brandName {
	text-decoration: none;
	margin-top: 10px;
}

#settingsIcon {
	height: 35px;
	margin-left: 57%;
	margin-right: 5px;
	margin-top: 13px;
}

.allButtons {
	display: flex;
	flex: 1;
	float: right;
	justify-content: flex-end;
}