#cTables {
    max-height: 250px !important;
}


#updateBtn {
    background-color: #00b94d;
    float: left;
    margin-right: 20px;
}

#buttonRow {
    display: inline-flex;
    width: 100%;
    padding: 3rem;
    padding-left: 0;
    padding-top: 0;
}

h1 {
    margin: 
  60px 
  20px 
  10px 
  20px;
  }

#selectGst{
display: block;
border: none;
color: rgb(112, 112, 112);
}

.select-tag{
    position: relative;
    display: flex;
    margin-top: 4px;
    flex-direction: column;
    box-shadow: none;
    border: none;
}