#mlImg {
    margin-bottom: auto;
    height: calc(100vh - 8.1rem);
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    display: block;
    margin-top: 5px;
}

#anlyContainer {
    padding-top: 4.6rem;
}
