.Userstable {
  width: 100%;
  height: fit-content;
  display: flex;
  margin-top: 10px;
}
.Users{
  margin-top: 3rem !important;
}
.title{
  margin-bottom: 20px;
  font-family: 'Courier New', Courier, monospace;
}

.border{
 border-bottom: 1px solid black;
}