.tabheader {
    display: inline-flex;
    padding: 0.1rem;
    margin-top:0.1rem;
    width: 100%;
}

h1 {
    margin: 
  100px 
  70px 
  80px 
  20px;
  }

#indevelopmentImg {
    height: 250px;
    margin-left: 37%;
    margin-top: 5%;
}

#indevelopmentText {
    font-size: medium;
    color: grey;
}
  /* .splitpdftab{
      margin-top: 00.1rem;
  } */

  #btnadd:hover{
    background-color: white;
  }