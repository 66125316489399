.button {
    background-color: #1e3deb;
    border: none;
    color: white;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
  }

  .button4 {border-radius: 12px;}