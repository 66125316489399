@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
footer {
    width: 100%;
    height: fit-content;
    background-color:#ffffff;
    display: inline-flex;
    align-items: baseline;
    box-shadow: 4px 0 2px 0px rgba(0,0,0,.2);
    position:fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  text-align: center;
  margin-bottom: -10px;
}


h6 {
    font-family: 'Roboto', sans-serif;
    color: #808080;
    margin-top: auto;
    margin-bottom: 5px;
    margin-left: 1rem;
}