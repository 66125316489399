@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

.containerResults {
	display:inline-flex;
	margin: 1rem;
	padding: 2rem 2rem ;
	width: 50%;
	margin-top: 2.6rem;
}

iframe {
	flex: 1;
	height: 80vh;
	width: 100%;
	margin-right: 25px;
}

.formDetails {
	position: fixed;
	right: 0;
	padding-top: 30px;
	padding-left: 4.8px;
	flex: 1;
	width: 50%;
}

@media screen and (max-height: 720px){
	#itemTable {
		height: 354px !important;
	}
	#buttonRow {
		margin-top: 72px !important;
	}
}

@media screen and (min-height: 650px){
	#buttonRow {
		width: 50%;
		margin-top: 74 !important;
	}

	.gobackbtn{
		margin-right: 10px !important;
		margin-top: -38px !important;
	}

	#UnlockBtn {
		margin-top: -38px !important;
		margin-right: 11rem !important;
	}
}


#itemTable {
	height: 430px !important;
	width: 100%;
	margin-top: -30px;
	flex: 1;
}

#downloadLink {
	text-decoration: none;
	color: white;
}

.MuiGrid-spacing-xs-3 > .MuiGrid-item {
	padding: 5px !important;
}

#buttonRow {
	display: inline-flex;
	flex-direction: row;
	padding: 2.6rem;
	margin-top: 110px;
	padding: 0.1rem;
}

/* #buttonback {
	margin-top: -90px;
} */

.gobackbtn {
	background-color: #00f;
	color: #fff;
	float: right;
	margin-right: 10px;
	margin-top: -3.9rem;
}

#UnlockBtn {
	margin-top: -2.4rem;
	margin-right: 19rem;
	float: right;
}

#downloadBtn {
	background-color: #00b94d;
	float: right;
	margin-right: 20px;
}

#approveBtn {
	background-color: #00b94d;
	float: right;
	margin-right: 20px;
}

#dwonloadBtnContainer {
	display: block;
	flex: 1;
}

#pushDataBtn {
	background-color: #ffae00;
}

#uploadBtnDoc {
	display: block;
	flex: 1;
	margin-right: 0;
}

/* #iFrameFileDisplay {
    flex: 1;
    height: 80vh;
    width: 100%;
    margin-top: -30px;
    margin-right: 15px;
    margin-left: -40px;
} */

#iFrameFileDisplay {
	left: 0;
	padding-top: 0px;
	flex: 1;
	width: 100%;
}

.toast-success-container {
	color: rgb(248, 242, 242) !important;
	border-radius: 8px !important;
	background: #34a853 !important;
	border: 1px solid #eaeceb !important;
	box-shadow: 0px 1px 5px rgba(253, 250, 250, 0.932) !important;
}

.Toastify__toast {
	margin-top: 55px;
}

@media (max-width: 2000px) {
	.gobackbtn {
		margin-right: 128px;
		margin-top: -37px;
		background-color: blue !important;	
	}
}

@media (max-width: 1700px) {
	#UnlockBtn {
		margin-top: -2.4rem;
		margin-right: 12rem;
	}
	.gobackbtn {
		margin-right: 64px;
		margin-top: -2.6rem;
		overflow: hidden;
		background-color: blue !important;
	}
}

@media (max-width: 1500px) {
	#UnlockBtn {
		margin-right: 8rem;
	}
	.gobackbtn {
		margin-right: 16%;
		margin-top: -5%;
		overflow: hidden;
		background-color: blue !important;
	}
}

@media (max-width: 1468px) {
	/* #buttonRow {
		justify-content: space-evenly;
		overflow: hidden;
	} */
	.gobackbtn {
		margin-top: -6%;
		margin-right: 12%;
		overflow: hidden;
		background-color: blue !important;
	}
	#UnlockBtn {
		margin-right: 14rem;
	}
}

@media (max-width: 1300px) {
	#buttonRow {
		justify-content: space-evenly;
		overflow: hidden;
	}
	.gobackbtn {
		margin-top: 1.9rem;
		margin-right: 10.5rem;
		overflow: hidden;
		background-color: blue !important;
	}
	#UnlockBtn {
		margin-top: 2rem;
		margin-right: 10rem;
		overflow: hidden;
	}
}

@media (max-width: 1153px) {
	.gobackbtn {
		margin-top: 1.9rem;
		margin-right: 9.5rem;
		overflow: hidden;
		background-color: blue !important;
	}
	#UnlockBtn {
		margin-top: 2rem;
		margin-right: 9rem;
		overflow: hidden;
	}
}

@media (max-width: 1009px) {
	.gobackbtn {
		margin-top: 1.9rem;
		margin-right: 5.5rem;
		overflow: hidden;
		background-color: blue !important;
	}
	#UnlockBtn {
		margin-top: 2rem;
		margin-right: 8.5rem;
		overflow: hidden;
	}
}

@media (max-width: 890px) {
	#unlockBtn {
		margin-right: 2.5rem;
	}
}

@media (max-width: 872px) {
	.gobackbtn {
		margin-top: 1.9rem;
		margin-right: 3.5rem;
		overflow: hidden;
		background-color: blue !important;
	}
	#UnlockBtn {
		margin-top: 2rem;
		margin-right: 8rem;
		overflow: hidden;
	}
}

@media (max-width: 790px) {
	.gobackbtn {
		margin-top: 2rem;
		margin-right: 5.5rem;
		overflow: hidden;
		background-color: blue !important;
	}
	#UnlockBtn {
		margin-top: 2rem;
		margin-right: 4.5rem;
		overflow: hidden;
	}
}

@media (max-width: 744px) {
	.gobackbtn {
		margin-top: 1.9rem;
		margin-right: 3rem;
		overflow: hidden;
		background-color: blue !important;
	}
	#UnlockBtn {
		margin-top: 2rem;
		margin-right: 7rem;
		overflow: hidden;
	}
}

@media (max-width: 728px) {
	.gobackbtn {
		margin-top: 1.3rem;
		margin-right: 0rem;
		overflow: hidden;
		background-color: blue !important;
	}
	#UnlockBtn {
		margin-top: 2rem;
		margin-right: 5rem;
		overflow: hidden;
	}
}

.close-btn{
	margin-top: 91px !important;
	margin-left: 32rem !important;
	background-color: blue !important;
	color: white !important;
	overflow: hidden;
	float: right;

}

.gobackbtn{
	color: white !important;
}