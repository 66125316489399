@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#empty {
  display: none;
}

#mainCont {
  min-height: 100vh;
}
body::-webkit-scrollbar {
  width: 12px;         /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #e28000;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: white;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #e28000  /* creates padding around scroll thumb */
}
#mainImg {
    max-height: 25rem;
    min-height: 25rem;
    flex: 1 1;
}

.table{
    height:  calc(100vh-4rem) !important;
}
#btnUploadDoc {
    padding-left: 50%;
}  
.headertab{
    display: inline-flex;
    padding: 2rem;
    margin-top:3rem;
    width: 100%;
    
}

#btn {
    margin: auto;
    flex: 1 1;
}

#fileGirl {
    display: block;
    margin-left: 30%;
    height: 250px;
    width: 380px;
    margin-bottom: 20px;
}
.tabheader {
    display: inline-flex;
    padding: 0.1rem;
    margin-top:0.1rem;
    width: 100%;
}

h1 {
    margin: 
  100px 
  70px 
  80px 
  20px;
  }

#indevelopmentImg {
    height: 250px;
    margin-left: 37%;
    margin-top: 5%;
}

#indevelopmentText {
    font-size: medium;
    color: grey;
}
  /* .splitpdftab{
      margin-top: 00.1rem;
  } */

  #btnadd:hover{
    background-color: white;
  }
.button {
    background-color: #1e3deb;
    border: none;
    color: white;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
  }

  .button4 {border-radius: 12px;}
.ellipse{
    height: 30px;
    width: 60px;
    margin-left: 10px;
    color:white;
    border: 1px solid #8DABC4;
    padding:2px 8px;
    background-color: #2196f3;
    border-radius: 16px;
}
.set-2{
    display: flex;
    float: right;
    flex-direction: row;
    margin-left: auto;
}
/* .refresh-btn{
    color: black;
    border: 1px solid black;
}
.refreshApproved-btn{
    border: 1px solid black;
    margin-left: 10px;
} */
.MuiPaper-elevation1 {
    box-shadow: 0px 2px 0px -1px rgb(0 0 0 / 18%), 
    0px 1px 1px 0px rgb(0 0 0 / 14%), 
    0px 1px 3px 1px rgb(0 0 0 / 12%) !important;
}
#test-table-xls-button {
    background-color: #d6d7d74d !important;
    color: black !important;
}
.tabheader {
    display: inline-flex;
    padding: 0.1rem;
    margin-top:0.1rem;
    width: 100%;
}

h1 {
  margin: 
  100px 
  70px 
  80px 
  20px;
  }
  .splitpdftab{
      margin-top: 00.1rem;
  }
  .test {
    width:200px;
    display:flex;
    align-items: right;
    overflow: auto;
    white-space: nowrap;
    margin:0px auto;
  }
.header-btn{
height: 35px;
margin-left: 60%;
margin-top: 13px;
margin-bottom: -25px;
display: flex;
position: relative;
flex-direction: row;
justify-content: end;
}
.upload-btn{
  margin-right: 5px !important;
}
.local-btn{
  margin-right: 5px !important;
}
.email-btn{
  margin-right: 5px;
}

.download-table-xls-button {
     margin-top: 10px;
     margin-left: 50%;
     margin-bottom: 10px;
     background-color: blue;
     color: azure;
}

#test-table-xls-button {
     padding: '3px 1px';
     border-radius: 3PX;
     border: 2px solid black;
     width: 140px;
     margin: 10px 10px;
     -webkit-transform: uppercase;
             transform: uppercase;
     font-size: 15px;
}
.download-table-xls-button {
    margin-top: 10px;
    margin-left: 50%;
    margin-bottom: 10px;
}

#test-table-xls-button {
    padding: '3px 1px';
    border-radius: 3PX;
    border: 2px solid black;
    width: 140px;
    margin: 10px 10px;
    -webkit-transform: uppercase;
            transform: uppercase;
    font-size: 15px;
}

#dropdown{
   width: 120px;
   margin-right: 30px;
}
.loader{
    color: rgba(0,0,0,0.6);
    text-shadow: 2px 8px 6px rgba(0,0,0,0.2),
    0px -5px 35px rgba(255,255,255,0.3);
    font-size: 25px;
}
.download-table-xls-button {
    margin-top: 10px;
    margin-left: 50%;
    margin-bottom: 10px;
    background-color: blue;
    color: azure;
}

#test-table-xls-button {
    padding: '3px 1px';
    border-radius: 3PX;
    border: 2px solid black;
    width: 140px;
    margin: 10px 10px;
    -webkit-transform: uppercase;
            transform: uppercase;
    font-size: 15px;
}

#dropdown-basic-button{
    width: 100px;
    margin-left: 50vw;
    height: 40px;
}
/* #imgFileDisplay {
    flex: 1;
    width: 50%;
    height: 100%;
    margin-right: 25px;
    margin-bottom: 0;
} */


#iFrameFileDisplay {
    flex: 1 1;
    height: 120vh;
    width: 100vh;
    margin-top: 0px;
    margin-right: 0px;
    margin-left: 0px;
    border: 1px solid rgb(73, 72, 72);


}

.main {
    flex: 1 1;
    height: 130vh;
    width: 100%;
    margin-left: -10px;
    float: left;
}

@media screen and (min-width: 1240px) {
    .main{
        margin-right: -14px;
        margin-left: -20px;
    }
}

@media screen and (min-width: 1280px) {
    .main{
        margin-right: -10px;
        margin-left: -7px;
    }
}

@media screen and (min-width: 1366px) {
    .main{
        margin-right: 5px;
        margin-left: -6px;
    }
}  

@media screen and (max-width: 1024px) {
    .main{
        margin-right: 5px;
        margin-left: -6px;
    }
}

.Pre {
    margin: 5px;
}

.nxt {
    margin: 5px;
}


.react-pdf__Page__canvas {
    flex: 1 1 !important;
    width: 400px !important;
    height: 500px !important;
    padding: 0em !important;
    margin: 1em !important;
    border: 1px solid black !important;
}

#imgConatiner {
    flex: 1 1 !important;
    width: 600px !important;
    height: auto !important;
    min-height: 500px !important;
    margin: 0 auto !important;
}

.pagec {
    display: flex;
    align-items: left;

}

.zoom {
    position: relative;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom : -30px;
    z-index: 1;
    background-color: white;
}

.zoomin {
    margin-right: 5px;
    margin-left: 30px;
    padding: 0px;
}

.zoomout {
    margin-right: 5px;
    padding: 0px;
}

.cancel {
    margin-right: 45px;
    padding: 0px;
}

.imageContainer {
    cursor: crosshair;
    margin-top: 40px;
}

.rotate {
    margin-left: -40px;
}

.download-btn{
    margin-left: 5px;
}

.zoomBtn {
    margin-left: 17px;
}

.magnifyeBtn {
    margin-left: 5px;
}

.magnify{
    margin-top: 40px;
}
#rootTable{
    width: 100% !important;
    height: 250px !important;
}

#containerTable{
    height: 250px !important;
   max-height: 250px !important;
}

.newCol {
    margin: 10px
}

#columnheader {
    justify-content: space-between;
    display: flex;
    margin: auto;
}
.headcolumns{
    font-size: 25px;
}

.hide {
    display: none;
}

.lineitems-field-span{
    max-width: 25%;
} 

.main-input{
    display: flex;
    flex-direction: column
}

.lineitems-input{
    max-width:100%
}
#rootTable{
    width: 100% !important;
    height: 250px !important;
}

#containerTable{
    height: 250px !important;
   max-height: 250px !important;
}

.newCol {
    margin: 10px
}

#columnheader {
    justify-content: space-between;
    display: flex;
    margin: auto;
}
.headcolumns{
    font-size: 25px;
}

.hide {
    display: none;
}

.lineitems-field-span{
    max-width: 25%;
} 

.main-input{
    display: flex;
    flex-direction: column
}

.lineitems-input{
    max-width:100%
}
#tableContainerExtra {
    width: 100%;
    max-height: 300px !important;
}

#tableContainerExtra {
    margin-top: 10px;
    margin-bottom: 30px;
    border-radius: 12px;
    } 


#rawTablesContainer {
    overflow: auto;
    width: 100%;
    height: 350px;
}

#cTables {
    max-height: 250px !important;
}


#updateBtn {
    background-color: #00b94d;
    float: left;
    margin-right: 20px;
}

#buttonRow {
    display: inline-flex;
    width: 100%;
    padding: 3rem;
    padding-left: 0;
    padding-top: 0;
}

h1 {
    margin: 
  60px 
  20px 
  10px 
  20px;
  }

#selectGst{
display: block;
border: none;
color: rgb(112, 112, 112);
}

.select-tag{
    position: relative;
    display: flex;
    margin-top: 4px;
    flex-direction: column;
    box-shadow: none;
    border: none;
}
.containerResults {
	display:inline-flex;
	margin: 1rem;
	padding: 2rem 2rem ;
	width: 50%;
	margin-top: 2.6rem;
}

iframe {
	flex: 1 1;
	height: 80vh;
	width: 100%;
	margin-right: 25px;
}

.formDetails {
	position: fixed;
	right: 0;
	padding-top: 30px;
	padding-left: 4.8px;
	flex: 1 1;
	width: 50%;
}

@media screen and (max-height: 720px){
	#itemTable {
		height: 354px !important;
	}
	#buttonRow {
		margin-top: 72px !important;
	}
}

@media screen and (min-height: 650px){
	#buttonRow {
		width: 50%;
		margin-top: 74 !important;
	}

	.gobackbtn{
		margin-right: 10px !important;
		margin-top: -38px !important;
	}

	#UnlockBtn {
		margin-top: -38px !important;
		margin-right: 11rem !important;
	}
}


#itemTable {
	height: 430px !important;
	width: 100%;
	margin-top: -30px;
	flex: 1 1;
}

#downloadLink {
	text-decoration: none;
	color: white;
}

.MuiGrid-spacing-xs-3 > .MuiGrid-item {
	padding: 5px !important;
}

#buttonRow {
	display: inline-flex;
	flex-direction: row;
	padding: 2.6rem;
	margin-top: 110px;
	padding: 0.1rem;
}

/* #buttonback {
	margin-top: -90px;
} */

.gobackbtn {
	background-color: #00f;
	color: #fff;
	float: right;
	margin-right: 10px;
	margin-top: -3.9rem;
}

#UnlockBtn {
	margin-top: -2.4rem;
	margin-right: 19rem;
	float: right;
}

#downloadBtn {
	background-color: #00b94d;
	float: right;
	margin-right: 20px;
}

#approveBtn {
	background-color: #00b94d;
	float: right;
	margin-right: 20px;
}

#dwonloadBtnContainer {
	display: block;
	flex: 1 1;
}

#pushDataBtn {
	background-color: #ffae00;
}

#uploadBtnDoc {
	display: block;
	flex: 1 1;
	margin-right: 0;
}

/* #iFrameFileDisplay {
    flex: 1;
    height: 80vh;
    width: 100%;
    margin-top: -30px;
    margin-right: 15px;
    margin-left: -40px;
} */

#iFrameFileDisplay {
	left: 0;
	padding-top: 0px;
	flex: 1 1;
	width: 100%;
}

.toast-success-container {
	color: rgb(248, 242, 242) !important;
	border-radius: 8px !important;
	background: #34a853 !important;
	border: 1px solid #eaeceb !important;
	box-shadow: 0px 1px 5px rgba(253, 250, 250, 0.932) !important;
}

.Toastify__toast {
	margin-top: 55px;
}

@media (max-width: 2000px) {
	.gobackbtn {
		margin-right: 128px;
		margin-top: -37px;
		background-color: blue !important;	
	}
}

@media (max-width: 1700px) {
	#UnlockBtn {
		margin-top: -2.4rem;
		margin-right: 12rem;
	}
	.gobackbtn {
		margin-right: 64px;
		margin-top: -2.6rem;
		overflow: hidden;
		background-color: blue !important;
	}
}

@media (max-width: 1500px) {
	#UnlockBtn {
		margin-right: 8rem;
	}
	.gobackbtn {
		margin-right: 16%;
		margin-top: -5%;
		overflow: hidden;
		background-color: blue !important;
	}
}

@media (max-width: 1468px) {
	/* #buttonRow {
		justify-content: space-evenly;
		overflow: hidden;
	} */
	.gobackbtn {
		margin-top: -6%;
		margin-right: 12%;
		overflow: hidden;
		background-color: blue !important;
	}
	#UnlockBtn {
		margin-right: 14rem;
	}
}

@media (max-width: 1300px) {
	#buttonRow {
		justify-content: space-evenly;
		overflow: hidden;
	}
	.gobackbtn {
		margin-top: 1.9rem;
		margin-right: 10.5rem;
		overflow: hidden;
		background-color: blue !important;
	}
	#UnlockBtn {
		margin-top: 2rem;
		margin-right: 10rem;
		overflow: hidden;
	}
}

@media (max-width: 1153px) {
	.gobackbtn {
		margin-top: 1.9rem;
		margin-right: 9.5rem;
		overflow: hidden;
		background-color: blue !important;
	}
	#UnlockBtn {
		margin-top: 2rem;
		margin-right: 9rem;
		overflow: hidden;
	}
}

@media (max-width: 1009px) {
	.gobackbtn {
		margin-top: 1.9rem;
		margin-right: 5.5rem;
		overflow: hidden;
		background-color: blue !important;
	}
	#UnlockBtn {
		margin-top: 2rem;
		margin-right: 8.5rem;
		overflow: hidden;
	}
}

@media (max-width: 890px) {
	#unlockBtn {
		margin-right: 2.5rem;
	}
}

@media (max-width: 872px) {
	.gobackbtn {
		margin-top: 1.9rem;
		margin-right: 3.5rem;
		overflow: hidden;
		background-color: blue !important;
	}
	#UnlockBtn {
		margin-top: 2rem;
		margin-right: 8rem;
		overflow: hidden;
	}
}

@media (max-width: 790px) {
	.gobackbtn {
		margin-top: 2rem;
		margin-right: 5.5rem;
		overflow: hidden;
		background-color: blue !important;
	}
	#UnlockBtn {
		margin-top: 2rem;
		margin-right: 4.5rem;
		overflow: hidden;
	}
}

@media (max-width: 744px) {
	.gobackbtn {
		margin-top: 1.9rem;
		margin-right: 3rem;
		overflow: hidden;
		background-color: blue !important;
	}
	#UnlockBtn {
		margin-top: 2rem;
		margin-right: 7rem;
		overflow: hidden;
	}
}

@media (max-width: 728px) {
	.gobackbtn {
		margin-top: 1.3rem;
		margin-right: 0rem;
		overflow: hidden;
		background-color: blue !important;
	}
	#UnlockBtn {
		margin-top: 2rem;
		margin-right: 5rem;
		overflow: hidden;
	}
}

.close-btn{
	margin-top: 91px !important;
	margin-left: 32rem !important;
	background-color: blue !important;
	color: white !important;
	overflow: hidden;
	float: right;

}

.gobackbtn{
	color: white !important;
}
#mlImg {
    margin-bottom: auto;
    height: calc(100vh - 8.1rem);
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    display: block;
    margin-top: 5px;
}

#anlyContainer {
    padding-top: 4.6rem;
}

.navContainer {
	width: 100%;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	background-color: #ffffff;
	display: inline-flex;
	padding: 0.5rem;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	position: fixed;
	z-index: 999;
}

#refreshBtn {
	height: 35px;
	margin-top: 13px;
	margin-left: 5px;
	margin-right: 20px;
}

.hide{
	display: none;
}

#imglogo {
	height: 3rem;
	margin-bottom: 0.25rem;
	margin-left: 1rem;
}

img:hover {
	cursor: pointer;
}

h3 {
	font-family: 'Roboto', sans-serif;
	color: #e28000;
	margin-top: auto;
	margin-bottom: auto;
	margin-left: 0.1rem;
}

h3:hover {
	cursor: pointer;
}

#uploadHeaderBtn1 {
	height: 35px;
	margin-left: 60%;
	margin-right: 5px;
	margin-top: 13px;
}


#homeBtn {
	height: 35px;
	margin-left: 0.1%;
	margin-top: 13px;
	margin-right: 00.5%;
}


#logoutBtn1 {
	height: 35px;
	margin-top: 13px;
}

#logout {
	height: 35px;
	margin-left: 75%;
	margin-top: 13px;
}

#uploadHeaderBtn {
	height: 35px;
	margin-right: 5px;
	margin-top: 13px;
}

#logoutBtn {
	height: 35px;
	margin-top: 13px;
}

#brandName {
	text-decoration: none;
	margin-top: 10px;
}

#settingsIcon {
	height: 35px;
	margin-left: 57%;
	margin-right: 5px;
	margin-top: 13px;
}

.allButtons {
	display: flex;
	flex: 1 1;
	float: right;
	justify-content: flex-end;
}
footer {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color:#ffffff;
    display: inline-flex;
    align-items: baseline;
    box-shadow: 4px 0 2px 0px rgba(0,0,0,.2);
    position:fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  text-align: center;
  margin-bottom: -10px;
}


h6 {
    font-family: 'Roboto', sans-serif;
    color: #808080;
    margin-top: auto;
    margin-bottom: 5px;
    margin-left: 1rem;
}
#warningMsg {
    color : rgb(190, 86, 0);
    text-align: justify;
    font-weight: 500;
    font-size: larger;
    margin-left: 50px;
}

#btnUploadDocError {
    margin-left: 50px;
    margin-top: 45px;
}
#btnUpload {
    background: #e28000 !important;
color: white !important;
text-align: center;
width: -webkit-max-content;
width: max-content;
}

#loadingImg {
    margin-top: 50%;
    margin-top: 50%;
    margin-bottom: auto;
    width: 450px;
    height: 170px;
}
.containerDisplay {
	display: inline-flex;
	padding: 1rem;
	width: 100%;
	margin-top: 5.6rem;
}

.react-pdf__Document {
	display: flex;
	flex-wrap: wrap;
}

.mainimg {
	flex: 1 1;
	height: 500px;
	width: 100%;
	margin-right: 25px;
}
.img {
	height: 500px;
}

.imgcontainer {
	position: relative;
	width: auto;
	height: 200 px;
	float: right;
	margin-left: 10px;
}
.checkbox {
	position: absolute;
	right: 20px;
	top: 20px;
	bottom: 40px;
	scale: 3.5;
}
.checkbox {
    height: 25px;
    width: 25px;
}

#itemTable {
	height: 430px;
	width: 100%;
	margin-top: -30px;
	flex: 1 1;
}

.react-pdf__Page__canvas {
	flex: 1 1 !important;
	width: 150px !important;
	height: 185px !important;
	padding: 0em !important;
	margin: 1em !important;
	border: 1px solid black !important;
}
.react-pdf__Page__textContent{
	display:none
}
.dialog {
	box-shadow: 0 8px 6px -6px black;
	position: static;
	left: 20%;
	top: 10%;
}

.image {
	width: 300px !important;
}


.btn{
	margin-top: 10%;
	display: flex;
	flex-direction: column;
}

.processBtn{
	background-color: blue !important;
	margin: 10px
}

.gobackBtn{
	background-color: blue !important;
	margin-top: 7rem !important;
}
.react-pdf__Page__textContent{
	display:none
}
/* @media (max-width: 350px) {
.btn{
	margin-right: -107px;
}
}

@media (max-width: 250px) {
	.btn{
		margin-right: -182px;
	}
}

@media (max-width: 1478px) and (min-width: 1100px) {
.btn{
	margin-right: 91px;
}
} */

.containerSplit {
	display: inline-flex;
	padding: 1rem;
	width: 100%;
	margin-top: 4.6rem;
}

.react-pdf__Document {
	display: flex;
	flex-wrap: wrap;
}

.mainimg {
	flex: 1 1;
	height: 500px;
	width: 100%;
	margin-right: 25px;
}
.img {
	height: 500px;
}

.imgcontainer {
	position: relative;
	width: auto;
	height: 200px;
	float: right;
	margin-left: 10px;
}
.checkbox {
	position: absolute;
	right: 20px;
	top: 20px;
	bottom: 40px;
}
.checkbox1 {
    height: 25px;
    width: 25px;
}


#itemTable {
	height: 430px;
	width: 100%;
	margin-top: -30px;
	flex: 1 1;
}

.react-pdf__Page__canvas {
	flex: 1 1 !important;
	width: 150px !important;
	height: 185px !important;
	padding: 0em !important;
	margin: 1em !important;
	border: 1px solid black !important;
}
.dialog {
	box-shadow: 0 8px 6px -6px black;
	position: static;
	left: 20%;
	top: 10%;
}

.image {
	width: 300px !important;
}


.btn{
	margin-top: 0%;
	display: flex;
	float: right;
	flex-direction: column;
}

.processBtn{
	background-color: blue !important;
	margin: 10px
}

.gobackBtn{
	background-color: blue !important;
	margin-top: 7rem !important;
}

.single-file-btn{
	background-color: blue !important;
	margin-top: 7rem !important;
}

.react-pdf__Page__textContent{
	display:none
}

.react-pdf__Page__textContent{
	display:none
}
.close-tab-Btn{
	background-color: blue !important;
	margin-bottom: 5rem !important;
    margin-top: 2rem !important;
}
/* .css-rqglhn-MuiTable-root{
	width: 53%;
} */
/* @media (max-width: 350px) {
.btn{
	margin-right: -107px;
}
}

@media (max-width: 250px) {
	.btn{
		margin-right: -182px;
	}
}

@media (max-width: 1478px) and (min-width: 1100px) {
.btn{
	margin-right: 91px;
}
} */

#loadingImg {
    margin-top: 50%;
    margin-top: 50%;
    margin-bottom: auto;
    width: 450px;
    height: 170px;
}
.MuiFilledInput-input {
    padding: 10px 10px 10px;
}
.MuiGrid-grid-xs-12 {
    flex-grow: 0;
    max-width: 70% !important;

}

#label{
    color: rgb(40, 106, 203);
    font: 25px monospace ;
    /* margin-left: 10px !important; */
    padding-top: 10px;
}

.MuiExpansionPanelSummary-content {
    margin: 0 !important;

}

.MuiInputBase-fullWidth {
    min-height: 60px !important;
   
}
.Userstable {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  margin-top: 10px;
}
.Users{
  margin-top: 3rem !important;
}
.title{
  margin-bottom: 20px;
  font-family: 'Courier New', Courier, monospace;
}

.border{
 border-bottom: 1px solid black;
}
.Users{
    display: inline-flex;
    padding: 3rem;
    width: 100%;
    margin-top: 4.6rem;
}
.personicon{
    width: 100%;
    border: none;
    padding-bottom: 8px;
    padding-left: 25px;
}


.Userstable {
    width: 100%;
    max-height: 70vh;
    display: flex;
    margin-top: 10px;
  }
.Users{
    flex: 1 1;
    width: 100%;
    margin-left: -10px;
    float: left;
    display: inline-flex;
    padding: 3rem;
    margin-top: 0rem;
}
.formDetails {
	right: 0;
	padding-top: 30px;
	padding-left: 4.8px;
	flex: 1 1;
	width: 50%;
}
.containerResults {
	display:inline-flex;
	margin: 1rem;
	padding: 2rem 2rem ;
	width: 50%;
	margin-top: 2.6rem;
}
/* .border{
   border: 5px solid black;
  } */
.fields{
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  width: 40vw;
  height: 20vh;
}
.notAssigned{
  display: block;
  color:rgb(74, 74, 119) ;
  /* color: rgb(95, 98, 255); */
  text-align: center ;
  margin-left: 10vw;
  margin-bottom: 5vh; 
  width: 15vw;
  height: 45px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px
}

.css-12q85cb-MuiTableCell-root {
  color: white !important;
}
.containerimage {
	display: inline-flex;
	padding: 1rem;
	width: 100%;
	margin-top: 4.6rem;
}

.imagedisplay{
	position: relative;
	margin-left: 10px;
}
.img1container {
	position: relative;
	float: left;
	width: auto;
	height: 200px;
	margin-left: 10px;
}
.checkbox1 {
	position: absolute;
	right: 20px;
	top: 20px;
	bottom: 40px;
}
.MuiExpansionPanel-rounded:last-child {
    margin-top: 50px !important;
}
